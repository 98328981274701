/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import 'fontsource-open-sans'
import "fontsource-open-sans/600.css"
import './src/assets/scss/main.scss'
